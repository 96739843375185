import request from '@/utils/request.js';
/**
 * 获取企业用户列表
 * @returns { Promise }
 */
export function getUserList(params) {
  return request({
    url: '/traffic-construction/org/userList',
    method: 'get',
    params
  });
};

/**
 * 切换用户状态
 * @returns { Promise }
 */
export function toggleStatus(data) {
  return request({
    url: '/traffic-construction/sysUser/enableOrDisableUser',
    method: 'post',
    data
  });
};

/**
 * 新增管理员
 * @returns { Promise }
 */
export function postNewAdmin(data) {
  return request({
    url: '/traffic-construction/sysUser/addAdminUser',
    method: 'post',
    data
  });
};

/**
 * 获取机构下拉列表
 * @returns { Promise }
 */
export function getOrgSelectList(params) {
  return request({
    url: '/traffic-construction/org/choseOrg',
    method: 'get',
    params
  });
};

/**
 * 为管理员分配机构
 * @returns { Promise }
 */
export function postGrantOrg(data) {
  return request({
    url: '/traffic-construction/sysUser/grantOrg',
    method: 'post',
    data
  });
};
