<template>
  <el-dialog
    width="530px"
    title="新增"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleColse"
  >
    <el-form ref="form" inline label-width="100px" :model="paramsForm" :rules="rules">
      <el-form-item label="管理员姓名" prop="name">
        <el-input disabled v-model="paramsForm.name" placeholder="请输入管理员姓名" style="width: 380px"></el-input>
      </el-form-item>
      <el-form-item label="分配机构" prop="orgId">
        <el-select
          v-model="paramsForm.orgId"
          filterable
          clearable
          placeholder="请选择或输入以搜索"
          style="width: 380px"
        >
          <el-option
            v-for="item in orgList"
            :key="item.orgId"
            :label="item.orgName"
            :value="item.orgId"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleColse">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getOrgSelectList, postGrantOrg } from '@/api/super-admin/enterprise-user-mgr.js';

export default {
  data() {
    return {
      visible: false,
      paramsForm: {
        id: '',
        name: '',
        orgId: ''
      },
      rules: {
        orgId: [{ required: true, message: '请选择管理机构', trigger: 'change' }]
      },
      orgList: [],
      confirmLoading: false
    };
  },
  methods: {
    open(row) {
      const { id, name } = row;
      this.visible = true;
      this.$nextTick(() => {
        this.paramsForm.id = id;
        this.paramsForm.name = name;
      });
      getOrgSelectList({ userId: id }).then(res => {
        this.orgList = res.data;
      });
    },
    handleColse() {
      this.visible = false;
      this.$refs.form.resetFields();
      this.paramsForm.id = '';
      this.paramsForm.grantOrgIdList = [];
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        this.confirmLoading = true;
        const params = {};
        params.id = this.paramsForm.id;
        params.grantOrgIdList = this.paramsForm.orgId.split(',');
        postGrantOrg(params)
          .then(res => {
            this.$message.success('分配管理机构成功！');
            this.$emit('on-success');
            this.handleColse();
          })
          .finally(() => {
            this.confirmLoading = false;
          });
      });
    }
  }
};
</script>
