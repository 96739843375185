<template>
  <div>
    <!-- query nav -->
    <el-form ref="queryFormRef" :inline="true" :model="queryForm" label-width="80px">
      <el-form-item prop="searchName">
        <el-input v-model="queryForm.searchName" placeholder="请输入姓名/电话/管理机构名称" style="width:280px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- table -->
    <el-button
      type="primary"
      icon="el-icon-plus"
      style="margin-bottom: 16px"
      @click="handleAddUser"
    >新增</el-button>
    <el-table v-loading="tableLoading" :data="userList">
      <el-table-column align="center" width="80" type="index" label="序号"></el-table-column>
      <el-table-column align="center" width="200" prop="name" label="姓名" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" width="200" prop="phone" label="电话"></el-table-column>
      <el-table-column align="center" label="管理机构" show-overflow-tooltip>
        <template slot-scope="{ row }">{{ row.orgName || '--' }}</template>
      </el-table-column>
      <el-table-column align="center" width="200" label="状态">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.status"
            active-color="#13ce66"
            :active-value="1"
            inactive-color="#ff4949"
            :inactive-value="2"
            @change="onToggleStatus($event, row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" width="200" label="操作">
        <template slot-scope="{ row }">
          <div>
            <span class="action-btn" @click="handleGrant(row)">分配机构</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :current-page="queryForm.pageNo"
      :page-size="queryForm.pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 50]"
      layout="total, sizes, prev, pager, next"
    ></el-pagination>
    <!-- 新增用户弹窗 -->
    <UserInfoDialog ref="userRef" @on-success="handleReset" />
    <!-- 分配机构 -->
    <GrantOrgDialog ref="grantRef" @on-success="getUserListData" />
  </div>
</template>

<script>
import UserInfoDialog from './userInfoDialog.vue';
import GrantOrgDialog from './grantOrgDialog.vue';
import { getUserList, toggleStatus } from '@/api/super-admin/enterprise-user-mgr.js';

export default {
  components: { UserInfoDialog, GrantOrgDialog },
  data() {
    return {
      total: 0,
      queryForm: {
        pageNo: 1,
        pageSize: 10,
        searchName: ''
      },
      tableLoading: false,
      userList: []
    };
  },
  created() {
    this.getUserListData();
  },
  methods: {
    getUserListData() {
      this.tableLoading = true;
      getUserList(this.queryForm)
        .then(res => {
          this.total = res.data.total;
          this.userList = res.data.records;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleSizeChange(size) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = size;
      this.getUserListData();
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      this.getUserListData();
    },
    handleSearch() {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.getUserListData();
    },
    handleReset() {
      this.$refs.queryFormRef.resetFields();
      this.handleSearch();
    },
    onToggleStatus(status, row) {
      const { id, appId } = row;

      toggleStatus({ id, appId, status })
        .then(res => {
          this.$message.success(`${status === 1 ? '激活' : '冻结'}用户成功！`);
        })
        .catch(() => {
          // 更改状态失败，刷新列表
          this.getUserListData();
        });
    },
    handleAddUser() {
      this.$refs.userRef.open();
    },
    handleGrant(row) {
      // 判断是否分配了机构
      if (row.orgName) {
        return this.$alert('当前管理员已经分配了管理机构', '提示', {
          confirmButtonText: '确定'
        });
      }
      this.$refs.grantRef.open(row);
    }
  }
};
</script>

<style lang="less" scoped>
</style>
