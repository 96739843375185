<template>
  <el-dialog
    width="530px"
    title="新增"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleColse"
  >
    <el-form ref="form" inline label-width="100px" :model="paramsForm" :rules="rules">
      <el-form-item label="管理员姓名" prop="name">
        <el-input v-model="paramsForm.name" placeholder="请输入管理员姓名" style="width: 380px"></el-input>
      </el-form-item>
      <el-form-item label="管理员手机" prop="phone">
        <el-input v-model="paramsForm.phone" placeholder="请输入管理员手机" style="width: 380px"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleColse">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { postNewAdmin } from '@/api/super-admin/enterprise-user-mgr.js';

export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      const phoneReg = /^(?:(?:\+|00)86)?1\d{10}$/;
      if (value.length === 0) {
        callback(new Error('请输入管理员手机'));
      } else if (!phoneReg.test(value)) {
        callback(new Error('请输入正确的手机'));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      paramsForm: {
        name: '',
        phone: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入管理员姓名', trigger: 'blur' },
          { max: 10, message: '长度在 10 个字符之内', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入管理员手机', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ]
      },
      confirmLoading: false
    };
  },
  methods: {
    open() {
      this.visible = true;
    },
    handleColse() {
      this.visible = false;
      this.$refs.form.resetFields();
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        this.confirmLoading = true;
        postNewAdmin(this.paramsForm)
          .then(res => {
            this.$message.success('新增用户成功！');
            this.$emit('on-success');
            this.handleColse();
          })
          .finally(() => {
            this.confirmLoading = false;
          });
      });
    }
  }
};
</script>
